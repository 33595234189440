*,
*:before,
*:after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
  /* font: inherit; */
}
img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}
input,
select,
textarea {
  background-color: transparent;
  outline: none;
}
button {
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: 0;
}
body {
  /* min-height: 100vh; */
  /* font-weight: 400; */
  /* font-size: 16px; */
  /* line-height: 1; */
  /* font-family: 'Poppins', sans-serif; */

  /* font-weight: 500; */
}

.mt-40{
  margin-top: 40px !important;
}

.container-main {
  /* height: 100vh;   */
  /* display: grid; */
  /* grid-template-columns: repeat(12, 1fr); */
  /* grid-template-rows: auto 1fr; */
}

.sidebar{
  /* background-color: #0ea5e9; */
  grid-column: 1/ span 3;
  grid-row: 1/ span 2;
  /* color: white; */
  padding: 20px;
}


.sidebar .logo {
  max-height: 60px;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  gap: 15px;
  font-size: 24px;
}

.logo svg {
  max-height: 40px;
  /* fill: white; */
}

.item svg {
  max-height: 30px;
  /* fill: white; */
}

.sidebar .item {
  display: flex;
  align-items: center;
  gap: 15px;
  /* margin-top: 10px; */
  font-size: 18px;
}

.header {
  background-color: white;
  grid-column: 4/ span 9;
  grid-row: 1/ span 1;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.top svg {
  width: 30px;
  height: 30px;
}

.header .top {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  justify-content: space-between;
}

.header .search-bar {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-left: 30px;
}

.header .search-bar input {
  outline: none;
  /* border: 1px solid gray; */
  border: none;
  border-radius: 20px;
  /* max-width: 500px; */
  padding: 0 10px;
  background-color: #e2e8f0;
  height: 30px;

 
}

.header .profile {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 30px;
}
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.main-content {
  background-color: #e0e7ff;
  grid-column: 4/ span 9;
  grid-row: 2/ span 1;
  /* display: grid; */
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
}

.d-main{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px;
}

.user-info .title {
  font-size: 20px;
  font-weight: 600;
}

.main .button {
  /* display: flex; */
  align-items: center;
  gap: 20px;
  margin-right: 30px;
  color: white;
}

.button .btn {
  /* min-width: 100px;
  height: 40px; */
  padding: 8px;
  border-radius: 20px;
  background-color: #0ea5e9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content {
  padding: 30px;
}

.main-content .projects {
  grid-row: 1/ span 2;
}

.projects svg {
  width: 25px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.project-card {
  /* display: grid; */
  /* grid-template-columns: repeat(2, 1fr); */
  /* grid-template-rows: repeat(3, 1fr); */
  /* gap: 20px; */
  

}

.project-card .project-card-item {
  /* display:flex;
  flex-direction:column; */
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-left: 6px solid orange;
}

.projects .project-name {
  font-size: 18px;
  font-weight: 600;
}

.projects .project-desc {
  font-size: 16px;
  color: #94a3b8;

}

.desc {
  font-size: 16px;
  color: #94a3b8;
}

.projects .project-item-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.announcements-container-main {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  /* grid-template-columns: 1; */
  background-color: white ;
  border-radius: 10px;
  padding: 20px;
  
}

.announcements-container-main .item {
  display: flex;
  flex-direction: column;
}
.announcements-container-main .item .name {
  font-weight: 600;
}

.announcements-container-main .item .desc {
  font-size: 16px;
  color: #94a3b8;
  margin-bottom: 10px;
}

.announcements-container-main hr {
  border-top: 1px solid #94a3b8;
}

.trending-container-main {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr; 
  /* grid-template-columns: 1; */
  background-color: white ;
  border-radius: 10px;
  padding: 20px;
  gap: 10px;
}

.trending-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.trending .trending-item-desc {
  display: flex;
  flex-direction: column;
}

.item {
    background-color: transparent !important;
    /* background-color: cadetblue; */
    margin-bottom: 5px;
    /* padding: 5px 10px; */
    cursor: pointer;
}

