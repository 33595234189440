@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bree+Serif&family=EB+Garamond:ital,wght@0,500;1,800&display=swap');


body {

  
	}


.prodet{
		background: #DFC2F2;
	background-image: linear-gradient( to right, #ffffb3,#ffe6e6);
	background-attachment: fixed;	
	background-size: cover;
	
	}

.prodet #container{
	box-shadow: 0 15px 30px 1px grey;
	background: rgba(255, 255, 255, 0.90);
	text-align: center;
	border-radius: 5px;
	overflow: hidden;
	/* margin: 5em auto; */
	height: 400px;
	width: 1100px;
	
  
	
}



.prodet .product-details {
	position: relative;
	text-align: left;
	overflow: hidden;
	padding: 30px;
	height: 100%;
	float: left;
	width: 50%;

}

.prodet #container .product-details h1{
	font-family: 'Bebas Neue', cursive;
	display: inline-block;
	position: relative;
	font-size: 30px;
	color: #344055;
	margin: 0;
	
}

.prodet #container .product-details h1:before{
	position: absolute;
	content: '';
	right: 0%; 
	top: 0%;
	transform: translate(25px, -15px);
	font-family: 'Bree Serif', serif;
	display: inline-block;
	background: #ffe6e6;
	border-radius: 5px;
	font-size: 14px;
	padding: 5px;
	color: white;
	margin: 0;
	animation: chan-sh 6s ease infinite;

}



	


.prodet .hint-star {
	display: inline-block;
	margin-left: 0.5em;
	color: gold;
	width: 50%;
}


.prodet #container .product-details > p {
font-family: 'EB Garamond', serif;
	text-align: center;
	font-size: 18px;
	color: #7d7d7d;
	
}
.prodet .control{
	position: absolute;
	bottom: 20%;
	left: 22.8%;
	
}
.prodet .btn {

	transform: translateY(0px);
	transition: 0.3s linear;
	background:  #809fff;
	border-radius: 5px;
  position: relative;
  overflow: hidden;
	cursor: pointer;
	outline: none;
	border: none;
	color: #eee;
	padding: 0;
	margin: 0;
	
}

.prodet .btn:hover{transform: translateY(-6px);
	background: #1a66ff;}

.prodet .btn span {
	font-family: 'Farsan', cursive;
	transition: transform 0.3s;
	display: inline-block;
  padding: 10px 20px;
	font-size: 1.2em;
	margin:0;
	
}
.prodet .btn .price, .shopping-cart{
	background: #333;
	border: 0;
	margin: 0;
}

.prodet .btn .price {
	transform: translateX(-10%); padding-right: 15px;
}

.prodet .btn .shopping-cart {
	transform: translateX(-100%);
  position: absolute;
	background: #333;
	z-index: 1;
  left: 0;
  top: 0;
}

.prodet .btn .buy {z-index: 3; font-weight: bolder;}

.prodet .btn:hover .price {transform: translateX(-110%);}

.prodet .btn:hover .shopping-cart {transform: translateX(0%);}



.prodet .product-image {
	transition: all 0.3s ease-out;
	display: inline-block;
	position: relative;
	overflow: hidden;
	height: 100%;
	float: right;
	width: 45%;
	display: inline-block;
}

.prodet #container img {width: 100%;height: 100%;}

.prodet .info {
    background: rgba(27, 26, 26, 0.9);
    font-family: 'Bree Serif', serif;
    transition: all 0.3s ease-out;
    transform: translateX(-100%);
    position: absolute;
    line-height: 1.8;
    text-align: left;
    font-size: 105%;
    cursor: no-drop;
    color: #FFF;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.prodet .info h2 {text-align: center}
.prodet .product-image:hover .info{transform: translateX(0);}

.prodet .info ul li{transition: 0.3s ease;}
.prodet .info ul li:hover{transform: translateX(50px) scale(1.3);}

.prodet .product-image:hover img {transition: all 0.3s ease-out;}
.prodet .product-image:hover img {transform: scale(1.2, 1.2);}