@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Baloo 2", cursive; */
}

.contact-section .container1 .contactinfo {
    font-family: "Baloo 2", cursive;
  display: flex;
  top: 0;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* padding: 40px; */
  background-color: #333;
}
.contact-section .container1 .contactinfo h2 {
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}
.contact-section .container1 .contactinfo .info {
  position: relative;
  /* margin: 20px 0; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact-section .container1 .contactinfo .info li {
  position: relative;
  list-style: none;
  display: flex;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
}
.contact-section .container1 .contactinfo .info li span:nth-child(1) {
  width: 40px;
  min-width: 40px;
}
.contact-section .container1 .contactinfo .info li span:nth-child(1) img {
  max-width: 100%;
  filter: invert(83%);
  opacity: 0.5;
}
.contact-section .container1 .contactinfo .info li span:nth-child(2) {
  color: #fff;
  margin-left: 10px;
  font-weight: 100;
  opacity: 0.5;
}
.contact-section .container1 .contactinfo .info li:hover span:nth-child(1) img, .contact-section .container1 .contactinfo .info li:hover span:nth-child(2) {
  opacity: 1;
}
.contact-section .container1 .contactusform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 70px 50px;
  background-color: #fff;
  top: 0;
}
.contact-section .container1 .contactusform h2 {
  color: #882;
  font-size: 24px;
  font-weight: 700;
}
.contact-section .container1 .contactusform .formBox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 30px;
}
.contact-section .container1 .contactusform .formBox .inputBox {
  position: relative;
  margin: 0 0 35px 0;
  width: 100% !important;
}
.contact-section .container1 .contactusform .formBox .inputBox input,
.contact-section .container1 .contactusform .formBox .inputBox textarea {
  width: 100% !important;
  padding: 5px 0;
  resize: none;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  border: none;
  border-bottom: 1px solid #333;
  outline: none;
}
.contact-section .container1 .contactusform .formBox .inputBox textarea {
  /* min-height: 120px; */
}
.contact-section .container1 .contactusform .formBox .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  transition: 0.3s;
  pointer-events: none;
}
.contact-section .container1 .contactusform .formBox .inputBox input:focus ~ span,
.contact-section .container1 .contactusform .formBox .inputBox textarea:focus ~ span,
.contact-section .container1 .contactusform .formBox .inputBox input:valid ~ span,
.contact-section .container1 .contactusform .formBox .inputBox textarea:valid ~ span {
  transform: translateY(-20px);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #ff4136;
}
.contact-section .container1 .contactusform .formBox .inputBox input[type=submit] {
  position: relative;
  cursor: pointer;
  background-color: #333;
  color: #fff;
  border: none;
  max-width: 150px;
  padding: 12px;
}
.contact-section .container1 .contactusform .formBox .inputBox input[type=submit]:hover {
  background-color: #dcdcdc;
}
.contact-section .container1 .contactusform .formBox .w50 {
  width: 47%;
}
.contact-section .container1 .contactusform .formBox .w100 {
  width: 100%;
}

@media screen and (min-width: 700px) {
  .contact-section .container1 .contactinfo h2 {
    text-align: center;
  }
  .contact-section .container1 .contactinfo .info {
    flex-direction: row;
  }
  .contact-section .container1 .contactinfo li span:nth-child(2) {
    margin-right: 20px;
  }
}
@media screen and (min-width: 900px) {
 .contact-section section {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    min-height: 100vh;
    background-color: #ccc;
  }
 .contact-section section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    /* height: 100%; */
    background-color: #dcdcdc;
  }
 .contact-section section .container1 {
    position: relative;
    width: 1100px;
    min-height: 550px;
    display: flex;
    z-index: 1000;
    margin: 0 20px 0 20px;
  }
 .contact-section section .container1 .contactinfo {
    position: absolute;
    top: 20%;
    width: 350px;
    height: calc(100% - 25%);
    z-index: 1;
    box-shadow: 0 30px 30px rgba(0, 0, 0, 0.3);
    align-items: left;
  }
 .contact-section section .container1 .contactinfo h2 {
    text-align: left;
  }
 .contact-section section .container1 .contactinfo .info {
    flex-direction: column;
  }
 .contact-section section .container1 .contactinfo li span:nth-child(2) {
    margin-right: 0;
  }
 .contact-section section .container1 .contactusform {
    position: absolute;
    top: 10%;
    height: calc(100%);
    box-shadow: 0 50px 50px rgba(0, 0, 0, 0.5);
    margin-left: 150px;
    padding-left: 250px;
  }
 .contact-section section .container1 .contactusform .formBox {
    flex-direction: row;
  }
}