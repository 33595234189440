.careers{
    /* background-image: url("../../assets/image/car.jpg"); */
    mask-repeat: no-repeat;
    /* height: 100vh; */
}




*,
*::before,
*::after {
  box-sizing: border-box;
}
h1,
p {
  /* color: #000000; */
  /* text-align: center;
  font-family: century gothic;
  margin-top: 0;
  margin-bottom: 0.5rem; */
}
p {
  font-size: 14px;
}
#title {
  margin-top: 2rem;
  font-family: century gothic;
  font-size: 25pt;
}
#description {
  margin-bottom: 2rem;
}
p[id="personal-data"] {
  display: flex;
  margin: 0;
  padding: 0;
  /* color: #000000; */
  font-family: "Poppins", sans-serif;
}
/* body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
} */
.form-section{
    background-image: linear-gradient(115deg, rgba(168, 168, 168, 0.8), rgba(179, 179, 179, 0.7)), url(https://www.plantech.co.za/wp-content/uploads/2017/02/HV-substation-night-shot.jpg);

}
.boxform {
  background-color: #2e2e2e;
  padding-top: 13px;
  margin: auto;
  max-width: 700px;
  height: auto;
  border-radius: 5px;
  margin-bottom: 20px;
  display: block;
}
.boxform form {
  display: block;
  background-color: #2e2e2e;
  padding: 30px;
  margin: auto;
  max-width: 700px;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 30px;
}
label {
  display: block;
  color: #dadada;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  margin-bottom: 0px;
}
input[type="text"],
input[type="email"],
input[type="number"],
input[type="file"],
select {
  height: 38px;
  width: 100%;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
  border: 0;
  padding: 10px;
  background-color: white;
}
input {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
}
#dropdown {
  margin-top: 5px;
}
input[type="radio"],
input[type="checkbox"] {
  transform: scale(1.3);
  margin-right: 10px;
  margin-top: 10px;
}
label[id="name-label"],
label[id="email-label"],
label[id="number-label"],
label[id="role-label"],
label[id="work-time"],
label[id="expert-label"] {
  display: block;
  color: #dadada;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}
#expert-label {
  margin-top: 2em;
}
#describe {
  min-height: 120px;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  border: 0;
  padding: 10px;
  margin-top: 5px;
  resize: vertical;
}
#submit {
  height: 38px;
  width: 100%;
  font-size: 18px;
  border-radius: 5px;
  border: 0;
  padding: 10px;
  background-color: #37af65;
}
input[type="file"]{
  color: #000000;
  background-color: #dadada;
  padding-bottom: 35px;
  margin-bottom: 0;
}
.clue{
  display: block;
  color: #ff0000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}