

.slick-next{
    display: none !important;
  }
    .swiper {
      width: 100%;
      height: 100%;
    }
    
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
    
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  
    .slick-dots {
      position: absolute;
      bottom: 11px;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
  }
  
  slick-slide > div {
    margin: 0px !important;
  }
  
  
  
  