@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;1,200&family=Roboto:ital,wght@0,400;0,500;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap'); */
/*  */


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    /* background-color: hsl(0, 0%, 1%); */
  }


  .slick-slide > div {
    /* margin: 0 10px; */
  }
