@import url('https://fonts.googleapis.com/css2?family=Anta&family=DM+Serif+Display:ital@0;1&family=Rubik:wght@300..900&display=swap');




/* 
#root{
  overflow-x: hidden;
} */

::-webkit-scrollbar {
  /* background-color: transparent; */
}

.button{
 
  border-radius: 50px;
}

.leader-revers:nth-child(even){
  display: flex;
  flex-direction: row-reverse;
}
.leader-revers:nth-child(odd){
  display: flex;
  flex-direction: row;
}

@media screen and (max-width:480px) and (min-width:350px){
  .leader-revers:nth-child(even){
    display: flex;
    flex-direction: column;
  }
  .leader-revers:nth-child(odd){
    display: flex;
    flex-direction: column;
  }
  
}


.background-image{
  background-image: url("./assets/image/gas.jpg");
}
.skk{
      
        top: 0 !important;
        position: fixed !important;
        z-index: 7777777;
        width: 100%;
    
}
.arrow-icon{
    border: 2px solid black;
    padding: 0 10px;
   
    height: 17px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}


/* join team section */
.join-our-team{
    border: 2px solid;
    margin: 2px;
    padding: 10px 20px;
}

.first-material-box{
    position: relative;
    z-index: 3;
}
.first-material-box::before{
    position: absolute;
    content: "";
    left: 0;
    right: 83%;
    background-color: #FEC006;
    top: 0;
    bottom: 0;
    /* width: 100%; */
    border-radius: 50px;
    transition: all 0.4s ease;
    z-index: -1;
   
}
.first-material-box:hover::before{
    transition: all 0.4s ease;
    right: 0;
    border-radius: 50px;
}
.first-material-box:hover span{
z-index: 2;
}



.last-material-box{
    position: relative;
    z-index: 3;
}
.last-material-box::before{
    position: absolute;
    content: "";
    left: 0;
    right: 83%;
    background-color: #8DCCFF;;
    top: 0;
    bottom: 0;
    /* width: 100%; */
    border-radius: 50px;
    transition: all 0.4s ease;
    z-index: -1;
   
}
.last-material-box:hover::before{
    transition: all 0.4s ease;
    right: 0;
    border-radius: 50px;
}
.last-material-box:hover span{
z-index: 2;
}

.wrapper {
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}



/* toggel section on conpmay>>our-business */
.accordion {
    /* width: 500px; */
}

.item {
    background-color: white;
    /* background-color: cadetblue; */
    margin-bottom: 5px;
    /* padding: 5px 10px; */
}

.title {
    color: #85662b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.title h2 {
    color: black;
    font-size: 18px;
    font-family: sans-serif;
}

.title span {
    color: black;
    /* font-size: 33px; */
}

.content {
   
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content.show {
    height: auto;
    max-height: 999px;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
    line-height: 1.5;
    padding-top: 12px;
}
.materialssm{
    /* display: none; */
}

.company-overview:hover{
  opacity: 0.5;
  cursor: pointer;
animation: company-overview-image;

}

/* home page animation */

.about-us-image{
  width: 100%;
  height: 100%;

  /* position: relative; */
  animation: monFrame 2s linear alternate;

}
@keyframes monFrame {
  0%   {left:-100%; top:0px;}
  50%  {right:100%; top:0px;}
  /* 50%  {left:500px; top:500px;} */
  /* 75%  {left:0px; top:500px;} */
  100% {left:0px; top:0px;}
   } 

.about-us-contant{
  width: 100%;
  height: 100%;
 
  /* position: relative; */
  animation: contant 2s linear  alternate;

}


@keyframes contant {
  0%   {right:-120%; top:0px;}
  50%  {left:100%; top:0px;}
  /* 50%  {left:500px; top:500px;} */
  /* 75%  {left:0px; top:500px;} */
  100% {left:0px; left:0px;}
   } 

   /* me page animation end  */

/* business part */
   .mainclass:hover .buttom-contant{
display: none;

   }

   .middel-class{
    transition: all .5s linear;
    margin-top: -30px;
    display: block;
    opacity: 0;
   }

   .mainclass:hover .middel-class{
    display: block;
    transform: translateY(10px);
    margin-top: 0;
    opacity: 1;
   }

   

   .mainclass:hover::before{
    content: "";
    background-color: #181e14cc;
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
   
   }

   /* business part end */



   /* background image section start */

   .back-image{
    background: url('./assets/image/gas.jpg');
    background-size: cover;
    height: 100vh;
    background-attachment: fixed;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 99;
    background-position: center;
   }
   /* background image section end */






.counter-class{
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@keyframes company-overview-image {
  0%{
transform: rotate(0dec);
  }
  100%{
    transform: rotate(360dec);
  }
  
}

.css-nzinn5-MuiContainer-root {
  max-width: 539px;
}






/* .dropdown-btn{
    position: relative;
}
.dropdown-btn button{
    font-size: 20px;
    background-color: red;
    color: white;
    padding: 10px 20px;
}
.dropdown-item{
    position: absolute;
    top: 100%;
    visibility: hidden;
}
.dropdown-btn:hover .dropdown-item{
    visibility: visible;
} */



 /* Dropdown Button in what we do */
.dropdown-btn .container {
    /* position: relative; */
    /* top: 60%; */
    /* left: 40%; */
    transform: translate(-50%, -50%);
  }
  
  .dropdown-btn .btn {
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #5380F7;
    /* min-width: 300px; */
    border: 0;
    border-radius: 4px;
    /* box-shadow: 12px 12px rgba(0, 0, 0, 0.1); */
    box-sizing: border-box;
    padding: 16px 20px;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.2px;
    /* text-transform: uppercase; */
    overflow: hidden;
    cursor: pointer;
  }
  /* .dropdown-btn .btn:focus .dropdown, .btn:active .dropdown {
    transform: translate(0, 20px);
    opacity: 1;
    visibility: visible;
  } */

  .dropdown-btn .btn:hover .dropdown {
    visibility: visible;
    opacity: 1;
  }

  .dropdown-btn .btn .material-icons {
    border-radius: 100%;
    -webkit-animation: ripple 0.6s linear infinite;
            animation: ripple 0.6s linear infinite;
  }
  .dropdown-btn .btn .dropdown {
    
    position: absolute;
    margin-left: 10px;
    top: 100%;
    /* left: 0; */
    background: #FFFFFF;
    /* width: 30%; */
    border-radius: 4px;
    box-shadow: 10px 10px 10px 12px rgba(0, 0, 0, 0.1);
    text-align: left;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease;
  }
  
  .dropdown-btn .btn .dropdown:before {
    content: "";
    position: absolute;
    top: -6px;
    left: 20px;
    width: 0;
    height: 0;
    box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.05);
    border-top: 6px solid #FFFFFF;
    border-right: 6px solid #FFFFFF;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    transform: rotate(-45deg);
    mix-blend-mode: multiple;
  }
  .dropdown-btn .btn .dropdown li {
    z-index: 1;
    position: relative;
    background: #FFFFFF;
    padding: 0 20px;
    color: #666;
  }
  .dropdown-btn .btn .dropdown li.active {
    color: #5380F7;
  }
  .dropdown-btn .btn .dropdown li:first-child {
    border-radius: 4px 4px 0 0;
  }
  .dropdown-btn .btn .dropdown li:last-child {
    border-radius: 0 0 4px 4px;
  }
  .dropdown-btn .btn .dropdown li:last-child a {
    border-bottom: 0;
  }
  .btn .dropdown a {
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 10px 0;
    color: inherit;
    font-size: 10px;
    text-decoration: none;
  }


  .product-title{
    text-transform: capitalize
  }
  
  @-webkit-keyframes ripple {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
    }
  }
  
  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
    }
  }



  @media screen and (max-width:768px) and (min-width:350px){
    .materials{
        display: none;
    }

    .first-material-box{
        position: relative;
        z-index: 3;
    }
    .first-material-box::before{
        position: absolute;
        content: "";
        left: 0;
        right: 87%;
        background-color: #FEC006;
        top: 0;
        bottom: 0;
        /* width: 100%; */
        border-radius: 50px;
        transition: all 0.4s ease;
        z-index: -1;
       
    }
    .first-material-box:hover::before{
        transition: all 0.4s ease;
        right: 0;
        border-radius: 50px;
    }
    .first-material-box:hover span{
    z-index: 2;
    }
    .dropdown-btn .btn:hover .dropdown {
      visibility: visible;
      opacity: 1;
    }
    
    
    
    .last-material-box{
        position: relative;
        z-index: 3;
    }
    .last-material-box::before{
        position: absolute;
        content: "";
        left: 0;
        right: 87%;
        background-color: #8DCCFF;;
        top: 0;
        bottom: 0;
        /* width: 100%; */
        border-radius: 50px;
        transition: all 0.4s ease;
        z-index: -1;
       
    }
    .last-material-box:hover::before{
        transition: all 0.4s ease;
        right: 0;
        border-radius: 50px;
    }
    .last-material-box:hover span{
    z-index: 2;
    }
    
    .wrapper {
        margin: 0;
        padding: 0;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    
}


